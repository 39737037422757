import React from 'react';

import { Segment } from 'components/segment/Segment';
import SEO from 'components/seo';

// tslint:disable no-default-export
export default () => (
  <>
    <SEO title="Help page" />

    <Segment>
      <h1>Help Page</h1>
    </Segment>
  </>
);
